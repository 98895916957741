import $ from 'jquery';

window.settings = {
	sp: function () {
		return $(window).outerWidth() < 768;
	},

	pc: function () {
		return $(window).outerWidth() >= 768;
	},

	getOffsetTop: function (domEl) {
		let offsetTop = domEl.offsetTop;
		if (domEl.offsetParent) {
			offsetTop += window.settings.getOffsetTop(domEl.offsetParent);
		}
		return offsetTop;
	},

	resizeXWidth: window.innerWidth,
	resizeXTimeout: null,
	eventResizeX: function () {
		window.settings.scrollBarWidth = null;
		if (window.settings.resizeXWidth !== window.innerWidth) {
			clearTimeout(window.settings.resizeXTimeout);
			window.settings.resizeXWidth = window.innerWidth;
			$(window).trigger('resize-x');
			document.body.classList.add('is-resizing');
			window.settings.resizeXTimeout = setTimeout(function () {
				document.body.classList.remove('is-resizing');
				$(window).trigger('resize-x-end');
			}, 200);
		}
	},

	bodyTopFixed: 0,
	bodyFixed: function (fixed = true) {
		let bodyElem = $('body');
		if (fixed) {
			window.settings.bodyTopFixed = $(window).scrollTop();
			bodyElem.css({
				top: (window.settings.bodyTopFixed * -1) + 'px',
				overflow: 'hidden',
				touchAction: 'none',
			}).addClass('is-fixed');
		} else {
			bodyElem.css({
				top: '',
				overflow: '',
				touchAction: '',
			}).removeClass('is-fixed');
			$('body, html').scrollTop(window.settings.bodyTopFixed);
		}
		bodyElem.trigger('body-fixed', [fixed]);
	},

	isBodyFixed: function () {
		return $('body').hasClass('is-fixed');
	},

	isObserver: typeof ResizeObserver === 'function',
}

$(window).on('load', function () {
	$('body').addClass('appear');
	setTimeout(function() {
		$('body .animation-bg').fadeOut();
	}, 1500);

	$(window).on('orientationchange', window.eventResizeX);
	if (window.settings.isObserver) {
		new ResizeObserver(window.settings.eventResizeX).observe(document.body);
	} else {
		$(window).on('resize', window.eventResizeX);
	}

	window.settings.resizeXWidth = window.innerWidth;
	$(window).trigger('resize-x');
});
