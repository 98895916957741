import $ from 'jquery';

$(function () {
	const slideUp = $('.js-slideup')

	if (slideUp.length) {
		$(window).on('scroll load', function () {
			slideUp.each(function () {
				const heightEle = $(this).innerHeight()
				const scrollDis = $(window).scrollTop() + $(window).innerHeight()
				const posEle = $(this).offset().top + heightEle

				scrollDis >= posEle && $(this).addClass('is-on')
			})
		})
	}
})

// Animation wave-up for img - Exp: c-block5
$(function () {
	const eles = $('.js-animeimg')
	const hWindow = $(window).innerHeight() / 2

	if (eles.length) {
		eles.each(function () {
			const _thisEle = $(this)

			$(window).on('scroll load', function () {
				if ($(window).scrollTop() >= _thisEle.offset().top - hWindow) {
					_thisEle.addClass('is-on')
				}
			})
		})
	}
})

// Animation fade-in all
$(function () {
	const eles = $('.js-fadeInAll')

	if (eles.length) {
		eles.each(function () {
			const _thisEle = $(this)

			if (_thisEle.hasClass('js-fadeInAll-start')) {
				setTimeout(() => {
					_thisEle.addClass('is-on')
				}, 300);
			}

			$(window).on('scroll load', function () {
				const hWindow = $(window).innerHeight();
				if ($(window).scrollTop() >= _thisEle.offset().top - hWindow + 200) {
					_thisEle.addClass('is-on')
				}
			})
		})
	}
})
