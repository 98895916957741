import $ from "jquery";

$(function () {
	const navi = $('#js-product-navi');
	const body = $('#js-product-body');
	const item = $('.c-Product__item', navi);
	const info = $('.c-Product__info', body);

	item.on('click', function () {
		item.removeClass('is-active');
		info.removeClass('is-active');
		$(this).addClass("is-active");
		info.eq($(this).index()).addClass("is-active");
	});

	$('[data-product]').on('click', function () {
		let text = $(this).data('product').trim().toLowerCase();
		item.each(function () {
			let itemText = $(this).text().trim().toLowerCase();
			if (itemText === text) {
				item.removeClass('is-active');
				info.removeClass('is-active');
				$(this).addClass("is-active");
				info.eq($(this).index()).addClass("is-active");
				return false;
			}
		});
	});
});
