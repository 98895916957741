import $ from 'jquery';

$(function () {
	let header = $('#header');
	let navBar = $('#header-navbar');

	if (!header.length && !navBar.length) {
		return;
	}

	if (header.hasClass('is-fixed')) {
		$(window).on('load scroll', function () {
			header.css('left', ($(window).scrollLeft() * -1) + 'px');
		});
	}

	if (!header.hasClass('is-style2')) {
		$(window).on('load scroll', function () {
			if ($(window).scrollTop() > 100) {
				header.addClass('is-style2');
			} else {
				header.removeClass('is-style2');
			}
		});
	}

	$(navBar).on('click', function () {
		$(this).toggleClass('is-active');
		header.toggleClass('navbar-active');
		if (header.hasClass('navbar-active')) {
			window.settings.bodyFixed(true);
		} else {
			window.settings.bodyFixed(false);
		}
	});

	$('.js-navi1-toggle').on('click', function () {
		$(this).toggleClass('is-active');
		$(this).next('.c-navi1__sub').slideToggle();
	});
});

$(function () {
	$('#js-lang-current').on('click', function () {
		let list = $('#js-lang-list');
		if (list.length) {
			if (list.hasClass('show')) {
				list.hide(0).removeClass('show');
			} else {
				list.show(0).addClass('show');
			}
		}
	});
});
