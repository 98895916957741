import $ from 'jquery';
import Swiper from 'swiper/bundle';

// js-swiper-mv1
$(function () {
	$('.js-mv1-swiper').each(function () {
		let domThis = this;
		const slider = new Swiper(domThis, {
			loop: true,
			effect: 'fade',
			speed: 2000,
			slidesPerView: 1,
			autoplay: {
				enabled: false,
				delay: 2000,
				disableOnInteraction: false,
			},
		});
		$('body').on('anim-loaded', function () {
			slider.autoplay.start();
		});
	});
});

//js-topics-swiper
$(function () {
	$('.js-topics-swiper').each(function () {
		let domThis = this;
		let itemSwiper = null;
		let callSwiper = function () {
			if (window.settings.sp() && itemSwiper === null) {
				itemSwiper = new Swiper(domThis, {
					loop: false,
					slidesPerView: 1,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				});
			}

			if (window.settings.pc() && itemSwiper !== null) {
				itemSwiper.destroy();
				itemSwiper = null;
			}
		}
		callSwiper();
		$(window).on('resize-x-end', function () {
			callSwiper();
		});
	});
});

// Slider1
$(function() {
	const slider1Ele = $('.js-slider1')

	if(slider1Ele.length) {
		slider1Ele.each(function() {
			const _thisSlide = $(this)
			// const lenSlide = $(this).find('.c-slider1__item').length
			// const numSlide = lenSlide ? lenSlide / 2 : 0

			const options = {
				slidesPerView: 'auto',
				spaceBetween: 0,
				loop: false,
				speed: 2500,
				initialSlide: 0,
				autoplay: {
					delay: 3000,
					disableOnInteraction: false,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			}

			const slider1 = new Swiper(this, options);

			slider1.autoplay.stop()

			$(window).on('scroll load', function() {
				const pos = _thisSlide.offset().top - $(window).innerHeight() + 100

				const isAutoPlay = $(window).scrollTop() > pos

				isAutoPlay ? slider1.autoplay.start() : slider1.autoplay.stop()
			})
		})
	}
})

// Slider2
$(function() {
	const slider2Wrap = $('.js-slider2-wrap')

	if(slider2Wrap.length) {
		slider2Wrap.each(function() {
			let slider2 = null
			const _this = $(this)
			const btns = $(this).find('.js-slider2-btn')
			const slider = $(this).find('.js-slider2')

			if(slider.length) {
				const options = {
					speed: 1000,
					effect: 'fade',
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}
				}

				slider2 = new Swiper(slider[0], options);

				slider2.on('slideChange', function () {
					const idx = slider2.activeIndex + 1

					btns.parent().removeClass('is-active')
					_this.find(`.c-block14__list__item:nth-child(${idx})`).addClass('is-active')
				});
			}

			btns.on('click', function() {
				btns.parent().removeClass('is-active')
				$(this).parent().addClass('is-active')
				slider2.slideTo($(this).parent().index())
			})
		})
	}
})
