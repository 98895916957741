import $ from 'jquery';
import lightGallery from 'lightgallery';

$(function() {

	const body = $('body');
	const bodyHTML = $('html, body');
	let scrollPosi = 0

	function bodyFix() {

		scrollPosi = $(window).scrollTop();
		body.addClass('is-fixed').css({ top: -scrollPosi });
	}

	function bodyFixReset() {

		body.removeClass('is-fixed').css({ top: '0' });
		bodyHTML.scrollTop(scrollPosi);
	}

	if($('.js-lightgallery').length) {
		$('.js-lightgallery').each(function() {
			lightGallery(this, {
				speed: 300,
				mode: 'lg-fade',
				download: false,
				counter: false,
				enableDrag: false,
				enableSwipe: false,
				hideControlOnEnd: true,
				loop: false,
				slideEndAnimation: false,
				mobileSettings: {
					controls: true
				}
			});

			const htmlClose = `
				<span class="lg-close-cus js-lg-close">
					<span></span>
				</span>`

			function handleLg() {
				if($('.lg-close-cus').length) {
					$('.lg-close-cus').remove()
				}

				$('.lg-img-wrap').append(htmlClose)

				$('.js-lg-close').on('click', function() {
					$('.lg-close').trigger('click')

					bodyFixReset()
				})

				$('.lg-img-wrap img, .lg-next, .lg-prev').on('mousemove', function() {
					$('.lg-next, .lg-prev').addClass('lg-hovering')
				}).on('mouseleave', function() {
					$('.lg-next, .lg-prev').removeClass('lg-hovering')
				})
			}

			this.addEventListener('lgBeforeOpen', () => {
				setTimeout(() => {
					handleLg()
				}, 100)

				bodyFix()
			});

			this.addEventListener('lgAfterClose', () => {
				bodyFixReset()
			});

			$('.lg-icon').on('click', function() {
				handleLg()
			})
		})
	}
})
