import $ from 'jquery';

function wait(mSec) {
	let d = $.Deferred();
	setTimeout(function () {
		d.resolve();
	}, mSec);
	return d.promise();
}

// if ($("#js-loading").length) {
// 	$(window).on("scroll load resize", function () {
// 		if(!isLoaded) {
// 			$.when(loadingAnimation(), wait(3000)).done(function () {
// 				scroll_effectTtl();
// 				scroll_effect();
// 				img_effect();
// 				scroll_effectFadeIn();
// 			});
// 		} else {
// 			scroll_effectTtl();
// 			scroll_effect();
// 			img_effect();
// 			scroll_effectFadeIn();
// 		}
// 	});
// } else {
// 	$(window).on("scroll load resize", function () {
// 		scroll_effectTtl();
// 		scroll_effect();
// 		img_effect();
// 		scroll_effectFadeIn();
// 	});
// }

$(window).on('load', function () {
	const $body = $('body');
	if ($body.hasClass('page-top')) {
		setTimeout(function () {
			$body.addClass('anim-load');
		}, 500);

		setTimeout(function () {
			$body.trigger('anim-loaded');
			scroll_effectTtl();
			scroll_effect();
			img_effect();
			scroll_effectFadeIn();
		}, 3500);
	} else {
		scroll_effectTtl();
		scroll_effect();
		img_effect();
		scroll_effectFadeIn();
	}

	$(window).on("scroll resize", function () {
		scroll_effectTtl();
		scroll_effect();
		img_effect();
		scroll_effectFadeIn();
	});
});

//let isLoaded = false
// function loadingAnimation() {
// 	$.when($("#js-loading").addClass("is-active")).done(function () {
// 		setTimeout(function () {
// 			$("#js-loading").addClass("slideUp");
// 		}, 400);
// 		setTimeout(function () {
// 			$("#js-loading").addClass("loaded");
// 			isLoaded = true
// 		}, 1800);
// 	});
// }

function scroll_effectTtl() {
	$('.js-fadeInTtl').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).innerHeight();
		if (scroll > elemPos - windowHeight + 200) {
			$(this).addClass('scrollIn');
		} else {
		}
	});
}

function scroll_effect() {
	$('.js-fadeIn').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).innerHeight();
		if (scroll > elemPos - windowHeight + 200) {
			$(this).addClass('scrollIn');
		} else {
		}
	});
}

function scroll_effectFadeIn() {
	$('.js-fadeIn2').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).innerHeight();
		if (scroll > elemPos - windowHeight + 200) {
			$(this).addClass('scrollIn');
		} else {
		}
	});
}

function img_effect() {
	$('.js-imgEffect').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).innerHeight();
		if (scroll > elemPos - windowHeight + 200) {
			$(this).addClass('is-active');
		} else {
		}
	});
}
